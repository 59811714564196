#account-part{
    height: auto;
    width: 100%;
    position:absolute;
    top: 0;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    #account{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        background: black;
        span{
            width: 350px;
            display: flex;
            justify-content: space-evenly;
            a{
                font-family: 'quicksandbook', sans-serif;
                font-size: 12px;
                display: flex;
                justify-content: space-evenly;
                cursor: pointer;
                i{
                    padding-top: 3px;
                    padding-right: 5px;
                    display: flex;
                }
            }
        }
    }
}