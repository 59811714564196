.content-container {
    #content-container-prestations {
        >.container-img-bg {
            width: 100%;
            >img {
                width: 100%;
            }
        }
        position: relative;
        .container-body-prestations-top {
            width: 100%;
            height: 100px;
            transform-origin: 0% 100%;
            background: #ffffff;
        }
        .container-bloc {
            .container-body-prestations {
                >div {
                    >h2 {
                        color: #444440;
                        margin-bottom: 10px;
                        font-family: quicksandbold, sans-serif;
                        font-weight: bold;
                        letter-spacing: 2px;
                        text-transform: uppercase;
                        font-size: 40px;
                    }
                    >span {
                        color: #444440;
                        margin-bottom: 20px;
                        font-family: quicksandbold, sans-serif;
                        font-weight: bold;
                        letter-spacing: 0px;
                        text-transform: uppercase;
                        font-size: 20px;
                    }
                }
                padding-bottom: 0px;
                .descriptif {
                    display: flex;
                    >div {
                        width: 50%;
                        position: relative;
                        top: 50px;
                        .title-madina {
                            color: black;
                            font-size: 80px;
                            line-height: 30px;
                        }
                        div.content-desc {
                            margin-top: 5px;
                            display: flex;
                            flex-direction: column;
                            height: auto;
                            min-height: 380px;
                            color: #444440;
                            position:relative;
                            >span {
                                display: block;
                            }
                            span.title {
                                font-family: quicksandbold, sans-serif;
                                text-align: center;
                                font-size: 25px;
                            }
                            >span.price {
                                text-align: center;
                                font-size: 60px;
                                line-height: 60px;
                            }
                            >div.content-desc-text{
                                display: flex;
                                flex-direction: column;

                                >span.text {
                                    font-family: quicksandlight, sans-serif;
                                    text-align: center;
                                    font-size: 14px;
                                    line-height: 20px;
                                    color: #444440;
                                    strong {
                                        font-weight: bold;
                                    }
                                }
                                >span.text.strong {
                                    font-size: 18px;
                                }
                            }

                        }
                        div.bonus {
                            margin-top: 5px;
                            display: flex;
                            flex-direction: column;
                            height: auto;
                            min-height: 380px;
                            color: #444440;
                            >span {
                                display: block;
                                font-size: 14px;
                                margin-bottom: 24px;
                            }
                            span.title {
                                font-family: quicksandbold, sans-serif;
                                text-align: center;
                                font-size: 25px;
                                margin-bottom: 0;
                                line-height: 25px;
                                height: 50px;
                                position:relative;
                                >sub.baseline-madina{
                                    margin-bottom: 0;
                                    font-size: 35px;
                                    line-height: 20px;
                                    width: auto;
                                    position:absolute;
                                    top: 25px;
                                    right: 75px;
                                }
                            }
                            >span.price {
                                text-align: center;
                                font-size: 60px;
                                line-height: 60px;
                            }

                            >div.content-desc-text{
                                display: flex;
                                flex-direction: column;

                                >span.text {
                                    font-family: quicksandlight, sans-serif;
                                    text-align: center;
                                    font-size: 14px;
                                    line-height: 20px;
                                    color: #444440;
                                    strong {
                                        font-weight: bold;
                                    }
                                }
                                >span.text.strong {
                                    font-size: 18px;
                                }
                            }

                        }
                    }
                    >div.three-month {
                        >div {
                            background: #c8caee;
                            margin-right: 5px;
                            margin-top: 15px;
                            padding: 15px;
                            >div.content-desc-text{
                                >span.text{
                                    margin-bottom: 30px;
                                }
                            }
                        }
                    }
                    >div.four-month {
                        >div {
                            background: #f1ece9;
                            margin-right: 5px;
                            margin-top: 15px;
                            padding: 15px;
                            >div.content-desc-text{
                                >span.text{
                                    margin-bottom: 10px;
                                }
                            }
                        }
                    }
                    >div.five-month{
                        >div{
                            margin-right: 5px;
                            margin-top: 15px;
                            padding: 15px;
                            background: #eee9c6;
                        }
                    }
                    >div.consultation{
                        >div{
                            background: #eebccb;
                            margin-right: 5px;
                            margin-top: 15px;
                            padding: 15px;
                            min-height: 464px;
                        }
                    }
                    >div.videos{
                        >div{
                            background: #c6eee9;
                            margin-right: 5px;
                            margin-top: 15px;
                            padding: 15px;
                            min-height: 464px;
                            &.content-desc{
                                >span.title{
                                    text-transform: uppercase;
                                }
                                >div.content-desc-list{
                                    display: flex;
                                    flex-direction: column;
                                    span.title-list{
                                        font-size: 18px;
                                        width: 100%;
                                        text-align:left;
                                    }
                                    ul{
                                        margin: 0;
                                        padding-left: 20px;
                                        li{
                                            text-align:left;
                                        }
                                    }
                                    div.price{
                                        display: flex;
                                        width: 100%;
                                        flex-direction: row;
                                        justify-content: center;
                                        height: 50px;
                                        margin: 5px 0;
                                        position:relative;
                                        span{
                                            font-family: 'madina', sans-serif;
                                            font-size: 50px;
                                            &.origin{

                                                background: url(./../../../public/images/picto-cross.jpg) no-repeat;
                                                background-size: contain;
                                                margin-right: 5px;
                                                line-height: 60px;
                                                vertical-align:middle;
                                            }
                                            &.btn-pack{
                                                position :absolute;
                                                right: 0;
                                                font-family: 'quicksandbold', sans-serif;
                                                font-size: 20px;
                                                line-height: 20px;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                    }
                    &.descriptif-prices-premium{
                        >div{
                            >div.content-desc{
                                min-height: 450px;
                                >span.encart{
                                    position:absolute;
                                    right: -20px;
                                    top: 10px;
                                    line-height: 18px;
                                    transform: rotate(30deg);

                                }
                            }
                            >div.bonus{
                                min-height: 524px;
                            }
                        }
                    }
                    &.descriptif-prices-specifiques{
                        >div.consultation{
                            >div{
                                position:relative;
                                &.content-desc{
                                    >span.encart{
                                        position:absolute;
                                        left: -20px;
                                        top: 10px;
                                        line-height: 18px;
                                        transform: rotate(-30deg);

                                    }
                                    >span.price{
                                        >sub{
                                            font-size: 20px;
                                        }
                                    }
                                }
                                &.bonus{
                                    >span.encart{
                                        position:absolute;
                                        left: -20px;
                                        top: 10px;
                                        line-height: 18px;
                                        transform: rotate(-30deg);

                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .container-bloc-presentation {
            background: #ffffff;
            .container-body-prestations {
                h1 {
                    color: #817eb2;
                    margin-bottom: 30px;
                }
                >div {
                    >h2 {
                        color: #444440;
                        margin-bottom: 20px;
                    }
                    &:first-child {
                        >p {
                            text-align: center;
                            color: #b486b7;
                            font-size: 18px;
                            line-height: 30px;
                        }
                    }
                }
            }
        }
        .container-bloc-standard {
            .container-body-prestations {

            }
        }
        .container-bloc-link {
            background: #ffffff;
        }
        .container-body-link-top {
            width: 100%;
            height: 100px;
            transform-origin: 0% 100%;
            background: #ffffff;
        }
        div.container-img-bg {
            img {
                display: block;
            }
            img.bg-img-mobile {
                display: none;
            }
        }
    }
}
.content-container.prestations {
    #footer {
        .footer-top {
            transform: skewX(0deg) skewY(0deg);
            display: none;
        }
    }
}

@media screen and (max-width: 960px) {
    .content-container {
        #content-container-prestations{
            .container-body-prestations-top{
                display: none;
            }
            .container-bloc{
                padding-bottom: 0;
                position:relative;
                .container-body-prestations{
                    width: 100%;
                    .descriptif{
                        flex-direction: column;
                        >div{
                            top: 0;
                            width: 100%;
                            .title-madina{
                                font-size: 65px;
                                height: 70px;
                                display: block;
                                line-height: inherit;
                            }
                            &.consultation{
                                >div{
                                    min-height: auto;
                                }
                            }
                            &.videos{
                                >div{
                                    min-height: auto;
                                    &.content-desc{
                                        >div.content-desc-list{
                                            span.title-list{
                                                font-size: 15px;
                                                width: 100%;
                                                text-align:left;
                                            }
                                            ul{
                                                margin: 0;
                                                padding-left: 20px;
                                                li{
                                                    font-size: 15px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &.container-bloc-link{
                    margin-top: 20px;
                }
            }
        }
    }
}