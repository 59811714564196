@font-face {
    font-family: 'quicksandbook_oblique';
    font-style: normal;
    font-weight: 400;
    src: url('Quicksand/quicksand_book_oblique-webfont.eot');
    src: url('Quicksand/quicksand_book_oblique-webfont.eot?#iefix') format('embedded-opentype'), url('Quicksand/quicksand_book_oblique-webfont.woff') format('woff'), url('Quicksand/quicksand_book_oblique-webfont.ttf') format('truetype'), url('Quicksand/quicksand_book_oblique-webfont.svg#quicksandbook_oblique') format('svg');
}

@font-face {
    font-family: 'quicksandregular';
    font-style: normal;
    font-weight: 400;
    src: url('Quicksand/Quicksand-Regular.eot');
    src: url('Quicksand/Quicksand-Regular.woff') format('woff'),
            url('Quicksand/Quicksand-Regular.ttf') format('truetype'),
            url('Quicksand/Quicksand-Regular.svg') format('svg');
}

@font-face {
    font-family: 'quicksandbook';
    font-style: normal;
    font-weight: 400;
    src: url('Quicksand/quicksand_book-webfont.eot');
    src: url('Quicksand/quicksand_book-webfont.eot?#iefix') format('embedded-opentype'), url('Quicksand/quicksand_book-webfont.woff') format('woff'), url('Quicksand/quicksand_book-webfont.ttf') format('truetype'), url('Quicksand/quicksand_book-webfont.svg#quicksandbook') format('svg');
}

@font-face {
    font-family: 'quicksandlight';
    font-style: normal;
    font-weight: 400;
    src: url('Quicksand/quicksand_light-webfont.eot');
    src: url('Quicksand/quicksand_light-webfont.eot?#iefix') format('embedded-opentype'), url('Quicksand/quicksand_light-webfont.woff') format('woff'), url('Quicksand/quicksand_light-webfont.ttf') format('truetype'), url('Quicksand/quicksand_light-webfont.svg#quicksandlight') format('svg');
}

@font-face {
    font-family: 'quicksandlight_oblique';
    font-style: normal;
    font-weight: 400;
    src: url('Quicksand/quicksand_light_oblique-webfont.eot');
    src: url('Quicksand/quicksand_light_oblique-webfont.eot?#iefix') format('embedded-opentype'), url('Quicksand/quicksand_light_oblique-webfont.woff') format('woff'), url('Quicksand/quicksand_light_oblique-webfont.ttf') format('truetype'), url('Quicksand/quicksand_light_oblique-webfont.svg#quicksandlight_oblique') format('svg');
}

@font-face {
    font-family: 'quicksandbold_oblique';
    font-style: normal;
    font-weight: 400;
    src: url('Quicksand/quicksand_bold_oblique-webfont.eot');
    src: url('Quicksand/quicksand_bold_oblique-webfont.eot?#iefix') format('embedded-opentype'), url('Quicksand/quicksand_bold_oblique-webfont.woff') format('woff'), url('Quicksand/quicksand_bold_oblique-webfont.ttf') format('truetype'), url('Quicksand/quicksand_bold_oblique-webfont.svg#quicksandbold_oblique') format('svg');
}

@font-face {
    font-family: 'quicksanddash';
    font-style: normal;
    font-weight: 400;
    src: url('Quicksand/quicksand_dash-webfont.eot');
    src: url('Quicksand/quicksand_dash-webfont.eot?#iefix') format('embedded-opentype'), url('Quicksand/quicksand_dash-webfont.woff') format('woff'), url('Quicksand/quicksand_dash-webfont.ttf') format('truetype'), url('Quicksand/quicksand_dash-webfont.svg#quicksanddash') format('svg');
}

@font-face {
    font-family: 'quicksandbold';
    font-style: normal;
    font-weight: 400;
    src: url('Quicksand/quicksand_bold-webfont.eot');
    src: url('Quicksand/quicksand_bold-webfont.eot?#iefix') format('embedded-opentype'), url('Quicksand/quicksand_bold-webfont.woff') format('woff'), url('Quicksand/quicksand_bold-webfont.ttf') format('truetype'), url('Quicksand/quicksand_bold-webfont.svg#quicksandbold') format('svg');
}

@font-face {
    font-family: 'gagalin';
    font-style: normal;
    font-weight: 400;
    src: url('Gagalin/Gagalin-Regular.eot');
    src: url('Gagalin/Gagalin-Regular.eot?#iefix') format('embedded-opentype'), url('Gagalin/Gagalin-Regular.woff') format('woff'), url('Gagalin/Gagalin-Regular.ttf') format('truetype'), url('Gagalin/Gagalin-Regular.svg#gagalin-regular') format('svg');
}

@font-face {
    font-family: 'adamScript';
    font-style: normal;
    font-weight: 400;
    src: url('AdamScript/AdamScript.eot');
    src: url('AdamScript/AdamScript.eot?#iefix') format('embedded-opentype'), url('AdamScript/AdamScript.woff') format('woff'), url('AdamScript/AdamScript.ttf') format('truetype'), url('AdamScript/AdamScript.svg#adam-script') format('svg');
}

@font-face {
    font-family: 'brusher';
    font-style: normal;
    font-weight: 400;
    src: url('Brusher/Brusher-Regular.eot');
    src: url('Brusher/Brusher-Regular.eot?#iefix') format('embedded-opentype'), url('Brusher/Brusher-Regular.woff') format('woff'), url('Brusher/Brusher-Regular.ttf') format('truetype'), url('Brusher/Brusher-Regular.svg#adam-script') format('svg');
}