.content-container {
    #content-container-login {
        > .container-img-bg {
            width: 100%;

            > img {
              width: 100%;
            }
        }
        position: relative;
        .container-bloc-login{
            position:absolute;
            top: 300px;
            width: 100%;
            display: flex;
            justify-content: center;
            form{
                width: 300px;
                padding: 15px;
                background: rgba(255,255,255,0.8);
                label, h1{
                    font-family: 'quicksandregular', sans-serif;
                    color: black;
                }
            }
        }
    }
}