.content-container{
    #content-container-training{
        >.container-img-bg {
            width: 100%;
            >img {
              width: 100%;
            }
        }
        position: relative;
        >.container-bloc-training{
            background: #f1ece9;
            div{
                &.container-body-training-top {
                    width: 100%;
                    height: 100px;
                    transform-origin: 0 100%;
                    background: #f1ece9;
                }
                &.container-body{
                    display: flex;
                    flex-direction: row;
                    .container-training{
                        width: 50%;
                        height: 400px;
                        position:relative;
                        padding: 0 10px;
                        h1{
                            text-align:left;
                            font-family: 'brusher', sans-serif;
                            color: #4a728b;
                            font-size: 60px;
                            margin-bottom: 0;
                        }
                        span.baseline{
                            text-align:left;
                            font-family: 'brusher', sans-serif;
                            color: #4a728b;
                            font-size: 25px;
                            letter-spacing: 3px;
                            width: 100%;
                            display: block;
                        }
                        div.content-pack{
                            position:absolute;
                            top: 200px;
                            ul{
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                list-style-type: none;
                                li{
                                    color: black;
                                    margin-bottom: 10px;
                                }
                            }
                            a{
                                color: black;
                            }
                        }
                    }
                }
            }

        }

    }
    #content-container-training-show{
        >.container-img-bg {
            width: 100%;
            >img {
                width: 100%;
            }
        }
        position: relative;
        >.container-bloc-training{
            background: #f1ece9;
            div{
                &.container-body-training-top {
                    width: 100%;
                    height: 100px;
                    transform-origin: 0 100%;
                    background: #f1ece9;
                }
                &.container-body{
                    h1{
                        font-family: 'brusher', sans-serif;
                        color: #4a728b;
                        font-size: 60px;
                        margin-bottom: 0;
                    }
                    span.baseline{
                        font-family: 'brusher', sans-serif;
                        color: #4a728b;
                        font-size: 25px;
                        letter-spacing: 3px;
                        width: 100%;
                        display: block;
                    }
                    .container-body-topic{
                        h3{
                            color: black;
                        }
                        .container-body-movie{
                            color: black;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            margin-bottom: 10px;
                            span{
                                width: 30%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-family: 'quicksandbook', sans-serif;
                            }
                            >div.description{
                                width: 40%;
                                padding: 0 10px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-family: 'quicksandbook', sans-serif;
                                font-weight: 400;
                            }
                            video{
                                width: 30%;
                            }
                        }
                    }
                }
            }

        }

    }
}