.content-container {
  &.customer{
    footer{
      position:absolute;
      bottom: -13px;
      width: 100%;
    }
    #content-container-customer {
      > .container-img-bg {
        width: 100%;

        > img {
          width: 100%;
        }
      }
      position: relative;
      .container-bloc-customer{
        position:absolute;
        top: 230px;
        width: 100%;
        height: 600px;
        display: flex;
        justify-content: center;
        .container-body{
          width: 960px;
          height: 100%;
          background: rgba(255,255,255,0.8);
          display: flex;
          flex-direction: row;
          padding: 5px;
          div{
            &.customer-nav{
              width: 30%;
              padding-right: 10px;
              border-right: 1px solid #444440;
              ul.list-group{
                li{
                  margin-bottom: 10px;
                  cursor: pointer;
                  span{
                    color: black;
                    font-family: 'quicksandregular', sans-serif;
                  }
                }
              }
            }
            &.content{
              width: 70%;
              span{
                font-family: quicksandregular, sans-serif;
                color: black;
              }
            }
          }
        }
      }
    }
  }

}