.content-container {
  &.cart{
    footer{
      position:absolute;
      bottom: -13px;
      width: 100%;
    }
    #content-container-cart {
      > .container-img-bg {
        width: 100%;

        > img {
          width: 100%;
        }
      }
      position: relative;
      .container-bloc-cart{
        position:absolute;
        top: 230px;
        width: 100%;
        height: 600px;
        display: flex;
        justify-content: center;
        .container-body{
          width: 600px;
          height: 100%;
          background: rgba(255,255,255,0.8);
          padding: 5px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          h3{
            color: black;
            font-family: 'quicksandregular', sans-serif;
          }
          table{
            width: 100%;
            tbody, thead{
              tr{
                td, th{
                  &.price{
                    display: flex;
                    flex-direction: column;
                  }
                  span{
                    color: black;
                    font-family: 'quicksandregular', sans-serif;
                    a, i{
                      color: black;
                      font-family: 'quicksandregular', sans-serif;
                    }
                    &.original{
                      text-decoration-line: line-through;
                      color: #800a0a;
                      font-family: 'quicksandlight', sans-serif;
                      font-size: 12px;
                    }
                  }
                }
              }
            }

          }
          .cart-action{
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            span{
              a{
                color: black;

              }
            }
          }
        }
      }
    }
  }

}